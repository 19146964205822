<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button
                @click="fetchRecentSevenDays"
                :type="quickSelected === '7days' ? 'primary' : ''"
              >
                最近七天
              </a-button>
              <a-button
                @click="fetchRecentThirtyDays"
                :type="quickSelected === '30days' ? 'primary' : ''"
              >
                最近30天
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>

        <a-col :span="7">
          <a-form-item label="日期">
            <a-range-picker
              style="width: 100%"
              v-decorator="['day']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-spin :spinning="loading">
      <div
        ref="chart"
        style="height:420px;"
      />
    </a-spin>

    <div>
      提示：未经客户授权平台将不会查询历史轨迹信息；因运营商接口查询延时等原因，本平台所展示的轨迹数据仅供参考
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/time'
import { findSimCardHistoryLocation } from '@/api/sim_card'
import chinaJson from '@/assets/china.json'

require('echarts/lib/component/geo')
require('echarts/lib/chart/scatter')
require('echarts/lib/chart/effectScatter')

export default {
  name: 'HistoryLocation',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      locations: [],
      query: {},
      quickSelected: '7days',
      chart: null,
      isCapacityUnitCount: false,
      form: this.$form.createForm(this, { name: 'history_location_search' })
    }
  },
  mounted() {
    this.fetchRecentSevenDays()
  },
  computed: {
    // 指定图表的配置项和数据
    chartOption() {
    //   const colors = [this.$primaryColor]
      const option = {
        // 背景颜色
        backgroundColor: '#404a59',
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: 'item',
          alwaysShowContent: false,
          backgroundColor: '#0C121C',
          borderColor: 'rgba(0, 0, 0, 0.16);',
          hideDelay: 100,
          triggerOn: 'mousemove',
          enterable: true,
          textStyle: {
            color: '#DADADA',
            fontSize: '12',
            width: 20,
            height: 30,
            overflow: 'break'
          },
          showDelay: 100,
          formatter(params) {
            return `${params.name} 轨迹点数:${params.value[2]}`
          }
        },
        // 地图配置
        geo: {
          map: 'china',
          zoom: 1.6, // 缩放级别
          roam: true, // 鼠标拖动
          layoutCenter: ['50%', '68%'],
          layoutSize: 350,
          label: {
            // 通常状态下的样式
            normal: {
              show: false
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: '#fff'
              }
            }
          },
          // 地图区域的样式设置
          itemStyle: {
            normal: {
              borderColor: 'rgba(147, 235, 248, 1)',
              borderWidth: 1,
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                  }
                ],
                globalCoord: false // 缺省为 false
              },
              shadowColor: 'rgba(128, 217, 248, 1)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              areaColor: '#389BB7',
              borderWidth: 0
            }
          }
        },
        series: [
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            symbol: 'pin',
            legendHoverLink: true,
            symbolSize: [60, 60],
            // 标志里的内容以及样式
            label: {
              show: true,
              formatter(value) {
                return value.data.value[2]
              },
              color: '#fff'
            },
            // 标志的样式
            itemStyle: {
              normal: {
                color: 'rgba(255,0,0,.7)',
                shadowBlur: 2,
                shadowColor: 'D8BC37'
              }
            },
            data: this.locations,
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            zlevel: 1
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            effectType: 'ripple',
            showEffectOn: 'render',
            rippleEffect: {
              period: 10,
              scale: 10,
              brushType: 'fill'
            },
            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: 'rgba(255, 235, 59, .7)',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            zlevel: 1,
            data: this.locations
          }
        ]
      }

      return option
    }
  },
  methods: {
    handleReset() {
      this.form.resetFields()
      this.fetchRecentSevenDays()
    },

    // 根据数据绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.chart)

      // 地图注册，第一个参数的名字必须和option.geo.map一致
      this.$echarts.registerMap('china', chinaJson)

      // 使用刚指定的配置项和数据显示图表
      this.chart.setOption(this.chartOption)
    },

    // 最近七天数据
    fetchRecentSevenDays() {
      this.form.resetFields()
      this.quickSelected = '7days'
      this.query.day_begin = formatDate(this.$moment().subtract(6, 'days'))
      this.query.day_end = formatDate(this.$moment())
      this.fetchData()
    },

    // 最近30天数据
    fetchRecentThirtyDays() {
      this.form.resetFields()
      this.quickSelected = '30days'
      this.query.day_begin = formatDate(this.$moment().subtract(29, 'days'))
      this.query.day_end = formatDate(this.$moment())
      this.fetchData()
    },

    handleSearch(e) {
      e.preventDefault()
      this.quickSelected = ''

      const fieldsValue = this.form.getFieldsValue()
      this.query = {
        ...fieldsValue
      }

      // 选择了日期则搜索日期范围数据；未选择则加载七天数据
      if (fieldsValue.day && fieldsValue.day.length === 2) {
        this.query.day_begin = formatDate(fieldsValue.day[0])
        this.query.day_end = formatDate(fieldsValue.day[1])
      } else {
        this.fetchRecentSevenDays()
      }

      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSimCardHistoryLocation(this.id, this.query).then((res) => {
        if (res.code === 0) {
          res.data.forEach((ele, index) => {
            this.locations[index] = {
              name: ele.Province,
              value: [ele.Lon, ele.Lat, ele.Count]
            }
          })
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>
